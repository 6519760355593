.remove_test_section_icon {
  color: red;
  border: 2px solid red;
  padding: 5px 7px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.remove_test_section_icon:hover {
  color: white;
  background-color: red;
}

.add_test_section_icon {
  color: green;
  border: 2px solid green;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.add_test_section_icon:hover {
  color: white;
  background-color: green;
}

.add_test_option_icon {
  color: #375582;
  border: 2px solid #375582;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}


.add_test_option_icon:hover {
  color: white;
  background-color: #375582;
}
.no_posts {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post_footer {
  position: static;
  bottom: 0px;
}

.post_error_loading {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_cabinet_button {
  padding: 5px 10px;
  margin-top: 5px;
}

.badge_color {
  width: 05rem;
  height: 0.5rem;
}

.new_cabinet_page_title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.new_image_button {
  padding: 5px 10px;
  margin-top: 5px;
}

.new_cabinet_form {
  margin-top: 1rem;
}

.carousel-control-prev-icon {
  left: -1rem;
  border-bottom: 0;
  font-size: 40px;
  color: #444;
  border-radius: 50%;
  background-color: #ddd;
}

.carousel-control-next-icon {
  right: -1rem;
  border-bottom: 0;
  font-size: 40px;
  color: #444;
  border-radius: 50%;
  background-color: #ddd;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.date-picker{
  width: 100% !important;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%) !important;
  border: none !important; 
}

.dob{
}
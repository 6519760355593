.inactive_icons {
  color: red;
  border: 2px solid red;
  padding: 5px 7px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.inactive_icons:hover {
  color: white;
  background-color: red;
}

.active_icons {
  color: green;
  border: 2px solid green;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.active_icons:hover {
  color: white;
  background-color: green;
}
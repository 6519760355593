.dropdown-toggle{
  width: 100%;
  color: gray;
  text-transform: capitalize;
  text-align: left;
}
.dropdown-toggle:disabled{
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.dropdown-toggle-box {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}

.dropdown-toggle-box::-webkit-scrollbar {
  width: 10px;
}

.dropdown-toggle-box::-webkit-scrollbar-track {
  background-color: rgb(225, 222, 222);
  border-radius: 100px;
}

.dropdown-toggle-box::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 30px rgba(37, 27, 27, 0.3);
  border-radius: 100px;
}
/* .card .table td, .card .table th{
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}
.table th, .table td{
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
} */

.view_assessment_button:hover{
  color: white;
}
.option_item {
  padding: 10px;
  background-color: #fff;
  color: #9697A7;
  border-radius: 10px;
  margin: 0.5rem 0;
  border: 1px solid #9697A7;
}

/* .option_item:hover {
  background-color: #eaeaf3;
} */

.option_item_active{
  padding: 10px;
  color: white;
  border-radius: 10px;
  margin: 0.5rem 0;
  border: 1px solid #9697A7;
  background-color: #395783;
}

.option_prefix {
  display: inline;
  background-color: #F3F3F5;
  color: #9697A7;
  padding: 5px 10px;
  border-radius: 50%;
  margin-right: 10px;
}
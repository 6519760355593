/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.big_text {
  display: flex;
  width: 100%;
  /* place-items: center; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8rem 0;
  text-align: center;
}
.big_text h1 {
  font-size: 10rem;
  transform: translatez(20rem);
}
.color_blue {
  color: #375582;
  text-shadow: none;
  animation: visible 2s infinite;
}
.big_text p {
  letter-spacing: 1px;
}
.small_texts {
  display: flex;
  justify-content: space-around;
}
.small_texts p {
  color: #888a85;
  animation: float 8s infinite;
  transform: translatey(4rem);
}

@keyframes float {
  0% {
    transition: translatey(0);
  }
  100% {
    transform: translatey(-40rem);
    opacity: (0);
  }
}

@keyframes visible {
  0% {
    opacity: (1);
  }
  20% {
    opacity: (0.5);
  }
  50% {
    opacity: (0.3);
  }
  100% {
    opacity: (0.9);
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}

.answer-check {
  color: green;
  font-size: 1rem;
  margin-top: 1rem;
  border-radius: 50%;
  padding: 0.4rem;
  background-color: transparent;
  border: 2px solid green;
}

.answer-times {
  color: red;
  font-size: 1rem;
  margin-top: 1rem;
  border-radius: 50%;
  padding: 0.4rem 0.6rem;
  background-color: transparent;
  border: 2px solid red;
}

textarea, .assessment_list_modal {
  overflow-y: scroll;
}

textarea::-webkit-scrollbar, .assessment_list_modal::-webkit-scrollbar {
  width: 5px;
}

textarea::-webkit-scrollbar-track, .assessment_list_modal::-webkit-scrollbar-track {
  background-color: rgb(225, 222, 222);
  border-radius: 100px;
}

textarea::-webkit-scrollbar-thumb, .assessment_list_modal::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 30px rgba(37, 27, 27, 0.3);
  border-radius: 100px;
}

.top-to-btm {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 200;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 200;
}
.icon-style {
  background-color: #373f76;
  border-radius: 50%;
  height: 100px;
  width: 100px;

  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
